import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Features/SD_Card/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import SDTable from 'components/Web_User_Interface/1440p_Series/Features/SD_Card/sdTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // SD Card",
  "path": "/Web_User_Interface/1440p_Series/Features/SD_Card/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Please check the corresponding box and click submit to either format or unmount your SD card. Your SD card might be unavailable after an unexpected restart. Please wait for the operating system to check the file system for errors.",
  "image": "./WebUI_1440p_SearchThumb_Features_SD_Card.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Features_SD_Card.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Features Menu // SD Card' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Please check the corresponding box and click submit to either format or unmount your SD card. Your SD card might be unavailable after an unexpected restart. Please wait for the operating system to check the file system for errors.' image='/images/Search/WebUI_1440p_SearchThumb_Features_SD_Card.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Features_SD_Card.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Features/SD_Karte/' locationFR='/fr/Web_User_Interface/1440p_Series/Features/SD_Card/' crumbLabel="SD Card" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/86f3e7136d75fc55cccf87509872d28b/c9c3a/1440p_Settings_Features_SD_Card.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACC0lEQVQoz12SW08TURRG+z8Ug5c40GkLQSCdaadzaakUYWbOmc5MO7VouDUSQFEuiYEoRo2+GhP/gfpm4qs/bpnTSg08fNlvK2vv/eUKhQL5fB5d15menube3TvUtz4Rnv+idfyD1snPqzn+TnzxG7d7xK2bN9D1AvV6nYmJCSYnb5NToBEsj6ZpFPQiIunT29il82RAu7d5Jen6Dv2tfVbWIsqLFUyzhmlYw7m4YJBTdirKTtPuUyrOEPg+cSSIZIgMfWQYjCICIhEQhyv4awG23cSqutQsD7vWwChbI+DlusqwWCwRhpJuNyNJU6KojZQSKSOkEIg4Q268JOlsslz3cewRUKVcrpJToP+G/4FZ1qXT6ZKm6RAohESKcAhs9fZ5LDMyP6C55GDXFLCOoYCXhmqOgYEgSRKiKEIoKyGQUgzB6gydJOJwK+Vgp8mjFRur4uLU/gGvG86UZpEiIsuyMXC8ctQm9H329p7z9vU+exseDbeGazs0PBfDqF5/ikapNDs2VKAwDEdZbRGuLvPQXyMbDPj67g3fXm1jWyZNz8FzXIxyZVSb619W94rjmHa7TRLHdNKEbPeM7OA9Z0nG+arPlw8f+fP5gnjZxnM9PLeBaVpXDaemNPJ5HdNQ/apQqVRpLXmsxz5PB4f0n51y2ss4kYKToxccb/fxnXnmHiywML/I7MwcfwGPm2+0SGBn6gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/86f3e7136d75fc55cccf87509872d28b/e4706/1440p_Settings_Features_SD_Card.avif 230w", "/en/static/86f3e7136d75fc55cccf87509872d28b/d1af7/1440p_Settings_Features_SD_Card.avif 460w", "/en/static/86f3e7136d75fc55cccf87509872d28b/7f308/1440p_Settings_Features_SD_Card.avif 920w", "/en/static/86f3e7136d75fc55cccf87509872d28b/7586f/1440p_Settings_Features_SD_Card.avif 1289w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/86f3e7136d75fc55cccf87509872d28b/a0b58/1440p_Settings_Features_SD_Card.webp 230w", "/en/static/86f3e7136d75fc55cccf87509872d28b/bc10c/1440p_Settings_Features_SD_Card.webp 460w", "/en/static/86f3e7136d75fc55cccf87509872d28b/966d8/1440p_Settings_Features_SD_Card.webp 920w", "/en/static/86f3e7136d75fc55cccf87509872d28b/501f3/1440p_Settings_Features_SD_Card.webp 1289w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/86f3e7136d75fc55cccf87509872d28b/81c8e/1440p_Settings_Features_SD_Card.png 230w", "/en/static/86f3e7136d75fc55cccf87509872d28b/08a84/1440p_Settings_Features_SD_Card.png 460w", "/en/static/86f3e7136d75fc55cccf87509872d28b/c0255/1440p_Settings_Features_SD_Card.png 920w", "/en/static/86f3e7136d75fc55cccf87509872d28b/c9c3a/1440p_Settings_Features_SD_Card.png 1289w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/86f3e7136d75fc55cccf87509872d28b/c0255/1440p_Settings_Features_SD_Card.png",
              "alt": "Web User Interface - 1440p Series - Features SD Card",
              "title": "Web User Interface - 1440p Series - Features SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "the-sd-card-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-sd-card-menu",
        "aria-label": "the sd card menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The SD Card Menu`}</h3>
    <p>{`A click on the folder button will lead you to the SD card directory where all your snapshots and videos will be stored. Use your camera admin login to access the directory. But there are also more convenient ways to access your recordings via SD Card viewer (s. below), `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/SD_Card_Access/"
      }}>{`Third-Party Software`}</a>{` or our mobile phone app InstarVision for `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPhone/Recording/"
      }}>{`iPhone`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPad/Recording/"
      }}>{`iPad`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Android/InstarVision/Recording/"
      }}>{`Android`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Windows_Phone/Recording/"
      }}>{`Windows Phone`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Metro/Recording/"
      }}>{`Windows Metro`}</a>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SDTable mdxType="SDTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Click on `}<strong parentName="p">{`SD Card`}</strong>{` above the live video to open the SD card viewer:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ffab9d421f04a5ce15282b3b3a954476/00d43/1440p_Settings_Features_SD_Card_Viewer.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACDUlEQVQ4y52SWU/bQBRG/csKBa/B28zYsWlKQ0lIGpYkSJS0pASkEiBVCVJZXtqnvvc3nmocTBehqPTh6H4azz26vraRd8+o7oxJtseIrVPkXMaIzilx57SoBVuz3rx7Trr9EUNEAVLEiCgkDgOiwC+QcVSc6SyiCBEGBJ7N5us1jocD3u332etu0WltUH+5iu9Z2OYSxvr2LvvTa3oXl/QupvQnU3bOPtMt8hW755d0J1d0Tsb4QUi/v0evv8fx8QkXk098OBrRftNBCIntVjD6zZhvI5ebwTI3gyXuDi2+7D/jZvCcu/cm1weL3A4WmfQWCfwKjUaTarVKs9lkOBwyGo1ot9tUKhUc18VIqxn56hpJdbUgzV4gk+whqzSfkWRFk5bVajUajQatVot6vU6e51iWheM4GEIIhIiRQhSI+/p3llIShgFJkhTCMAxJ0xSlFJ7nsbCwgKsn1Bf/BS3XzVqoa5ZlRdZSXYs7Uj1NqGsp1K+pX//hjhDEQj5dqCV6Ki3UYlGuRv6nsKSc9Nf5E4RlUznN78zO/xAK1D06lw/nT1hm9bA/Ve4wiiWVULISPT7B40hEHCIiv6iBlDPiGOPtRsiPoxW+Dypkif70EjVvBUohRYTaPCQ5+ErePmHq+tx6Ae2VAEP5Fq3UZEOZuI6NbTvFHz8X28YJEhy1jhdm1JZNXpkWwbLJT/z1YGq25hxdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ffab9d421f04a5ce15282b3b3a954476/e4706/1440p_Settings_Features_SD_Card_Viewer.avif 230w", "/en/static/ffab9d421f04a5ce15282b3b3a954476/d1af7/1440p_Settings_Features_SD_Card_Viewer.avif 460w", "/en/static/ffab9d421f04a5ce15282b3b3a954476/7f308/1440p_Settings_Features_SD_Card_Viewer.avif 920w", "/en/static/ffab9d421f04a5ce15282b3b3a954476/445fc/1440p_Settings_Features_SD_Card_Viewer.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ffab9d421f04a5ce15282b3b3a954476/a0b58/1440p_Settings_Features_SD_Card_Viewer.webp 230w", "/en/static/ffab9d421f04a5ce15282b3b3a954476/bc10c/1440p_Settings_Features_SD_Card_Viewer.webp 460w", "/en/static/ffab9d421f04a5ce15282b3b3a954476/966d8/1440p_Settings_Features_SD_Card_Viewer.webp 920w", "/en/static/ffab9d421f04a5ce15282b3b3a954476/a5d4d/1440p_Settings_Features_SD_Card_Viewer.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ffab9d421f04a5ce15282b3b3a954476/81c8e/1440p_Settings_Features_SD_Card_Viewer.png 230w", "/en/static/ffab9d421f04a5ce15282b3b3a954476/08a84/1440p_Settings_Features_SD_Card_Viewer.png 460w", "/en/static/ffab9d421f04a5ce15282b3b3a954476/c0255/1440p_Settings_Features_SD_Card_Viewer.png 920w", "/en/static/ffab9d421f04a5ce15282b3b3a954476/00d43/1440p_Settings_Features_SD_Card_Viewer.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ffab9d421f04a5ce15282b3b3a954476/c0255/1440p_Settings_Features_SD_Card_Viewer.png",
              "alt": "Web User Interface - 1440p Series - Features SD Card",
              "title": "Web User Interface - 1440p Series - Features SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "replacing-the-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#replacing-the-sd-card",
        "aria-label": "replacing the sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Replacing the SD card`}</h3>
    <p>{`On the indoor cameras, the SD card is accessible directly on the housing. For the outdoor cameras, the housing must be opened to replace the SD card:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f87097cdbe37b9d3081e30c7160c7eea/d56b5/1440p_Settings_Features_SD_Card_Location.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABvUlEQVQY0y2QTW8SURSGD/APCAu3pTYaw5KdaYyxTXQWxia6qBpFZ8HHJFURNphQVkDHZFi0C1mS2CVxU1M3lsBPYAM2ITgyJDMk3M69w2TufHG1Mz7LN+fJm/cAY8xxHMuyaIDrumGCMaaUOo7juq7v+57nUdtGV1fyH/l3gKZpEN6ZprlarUzTJIQwxnRdHw6Hsix/Pzv7cX4+GAy63W6r+bl4cPDmdaZ2WON5vlKpQChQSu0AjLHneZZlTSYTRVFE8ej53pMPhcJH/u2Lvafb97a53Ycnx8epVCqXy4FhGBhjTVXn87lpmoZhUEoJIaPRaLlcttvtZ4+5ZvVTJfPqS4bPPLj/cn//uhOgWCxey4vF4tflpaqqCCFFUTzPG4/Hp19Pp9Npr9d7tLtzZ2vrdvLmtxu3mnd3su/fJTc2AKBUKgEhxLZtomkIIcMwws39fj+dTnc6nYuLn5IkFQThsFY7abXy+XwikYhGo//lcCfRdcuydF1HCIUyAMTjcY7jyuVytVrNZrPJzU0AiEQisVgMAARBAMaY7/uUUhy8bb1eM8Zms9lRgCiKUoAoivV6XZKkVkCj0fhX8BeGz0TKkJ2CegAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f87097cdbe37b9d3081e30c7160c7eea/e4706/1440p_Settings_Features_SD_Card_Location.avif 230w", "/en/static/f87097cdbe37b9d3081e30c7160c7eea/d1af7/1440p_Settings_Features_SD_Card_Location.avif 460w", "/en/static/f87097cdbe37b9d3081e30c7160c7eea/7f308/1440p_Settings_Features_SD_Card_Location.avif 920w", "/en/static/f87097cdbe37b9d3081e30c7160c7eea/eb15d/1440p_Settings_Features_SD_Card_Location.avif 1215w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f87097cdbe37b9d3081e30c7160c7eea/a0b58/1440p_Settings_Features_SD_Card_Location.webp 230w", "/en/static/f87097cdbe37b9d3081e30c7160c7eea/bc10c/1440p_Settings_Features_SD_Card_Location.webp 460w", "/en/static/f87097cdbe37b9d3081e30c7160c7eea/966d8/1440p_Settings_Features_SD_Card_Location.webp 920w", "/en/static/f87097cdbe37b9d3081e30c7160c7eea/34ce3/1440p_Settings_Features_SD_Card_Location.webp 1215w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f87097cdbe37b9d3081e30c7160c7eea/81c8e/1440p_Settings_Features_SD_Card_Location.png 230w", "/en/static/f87097cdbe37b9d3081e30c7160c7eea/08a84/1440p_Settings_Features_SD_Card_Location.png 460w", "/en/static/f87097cdbe37b9d3081e30c7160c7eea/c0255/1440p_Settings_Features_SD_Card_Location.png 920w", "/en/static/f87097cdbe37b9d3081e30c7160c7eea/d56b5/1440p_Settings_Features_SD_Card_Location.png 1215w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f87097cdbe37b9d3081e30c7160c7eea/c0255/1440p_Settings_Features_SD_Card_Location.png",
              "alt": "Web User Interface - 1440p Series - Features SD Card",
              "title": "Web User Interface - 1440p Series - Features SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      